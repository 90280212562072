<template>
  <main class="page home-old">
    <section class="section section__welcome section-welcome">
      <div class="section-welcome__wrapper section__wrapper">
        <div class="section-welcome__content">
          <div class="section-welcome__title">
            <h1 class="title title-lg">{{ $wt('webBeSafe') }}</h1>
          </div>

          <div class="section-welcome__description">
            <span class="text text-lg">{{
              $wt('webOnlineSecurity', {
                pricePerMonth,
              })
            }}</span>
          </div>

          <div class="section-welcome__image-wrapper hide-tablet hide-desktop">
            <img
              :src="mainImgSrc"
              alt="VPN Image"
              class="section-welcome__image"
            />
          </div>

          <div class="section-welcome__btn">
            <link-button
              :to="{ name: $ROUTER.NAME.PRICES }"
              :value="
                $t('getVPN', {
                  productName: $CONSTANTS.ENV.PRODUCT_NAME,
                })
              "
              size="lg"
            />
          </div>

          <div class="section-welcome__btn-description">
            <icon
              :name="$t('web.icon_check-circle')"
              color="var(--main)"
              fill
              width="16"
              height="16"
            >
              <checkCircle />
            </icon>
            <span class="text text-md mx-2">{{
              $t('web.money_back_guarantee')
            }}</span>
          </div>

          <div class="section-welcome__apps">
            <slot-link
              class="section-welcome__app"
              :to="$CONSTANTS.ENV.ANDROID_APP_URL"
              external
              height="48"
            >
              <img
                :src="require('@/assets/image/store/googleplay.png')"
                alt="Download on the Google Play"
              />
            </slot-link>
            <slot-link
              class="section-welcome__app"
              :to="$CONSTANTS.ENV.IOS_APP_URL"
              external
              height="48"
            >
              <img
                :src="require('@/assets/image/store/appstore-ios.png')"
                alt="Download on the App Store"
              />
            </slot-link>
          </div>
        </div>
        <div class="section-welcome__image-wrapper hide-mobile">
          <img
            :src="mainImgSrc"
            alt="VPN Image"
            class="section-welcome__image"
          />
        </div>
      </div>
      <div class="section-welcome__bg hide-mobile"></div>
    </section>

    <section class="section section__skew section-skew">
      <div class="section-skew__wrapper section__wrapper">
        <div class="section-skew__content">
          <div class="section-skew__title">
            <h2 class="title title-lg color-main-opposite">
              {{ $t('web.private_finally') }}
            </h2>
          </div>

          <div class="section-skew__description">
            <div class="section-skew__text">
              <span class="text text-lg color-main-opposite">{{
                $t('web.removes_everything', {
                  productName: $CONSTANTS.ENV.PRODUCT_NAME,
                })
              }}</span>
            </div>

            <ul class="section-skew__list">
              <li class="section-skew__li">
                <icon
                  :name="$t('web.icon_check-circle')"
                  color="var(--main-opposite)"
                  fill
                  width="14"
                  height="14"
                  class="point"
                >
                  <check />
                </icon>
                <span class="text text-lg color-main-opposite">{{
                  $t('web.no_one_can_see')
                }}</span>
              </li>
              <li class="section-skew__li">
                <icon
                  :name="$t('web.icon_check-circle')"
                  color="var(--main-opposite)"
                  fill
                  width="14"
                  height="14"
                  class="point"
                >
                  <check />
                </icon>
                <span class="text text-lg color-main-opposite">{{
                  $t('web.no_restrictions_on_content_and_censorship')
                }}</span>
              </li>
              <li class="section-skew__li">
                <icon
                  :name="$t('web.icon_check-circle')"
                  color="var(--main-opposite)"
                  fill
                  width="14"
                  height="14"
                  class="point"
                >
                  <check />
                </icon>
                <span class="text text-lg color-main-opposite">{{
                  $t('web.one_click_and_you_defend')
                }}</span>
              </li>
            </ul>

            <link-button
              class="ection-skew__btn"
              :to="{ name: $ROUTER.NAME.PRICES }"
              :value="
                $t('getVPN', {
                  productName: $CONSTANTS.ENV.PRODUCT_NAME,
                })
              "
              color="white"
              transparent
              size="lg"
            />
          </div>
        </div>
      </div>
      <div class="section-skew__bg-container">
        <div class="section-skew__bg"></div>
        <div
          class="section-skew__bg-image"
          :style="`background-image: url(${require('@/assets/image/home/skew-block.jpg')});`"
        ></div>
      </div>
    </section>

    <section class="section section__apps section-apps">
      <div class="section-apps__wrapper section__wrapper">
        <div class="section-apps__content">
          <div class="section-apps__title">
            <h2 class="title title-lg">
              {{ $t('web.easy_to_use_apps') }}
            </h2>
          </div>

          <div class="section-apps__description">
            <div class="section-apps__text">
              <span class="text text-lg">{{
                $t('web.subscription_comes', {
                  productName: $CONSTANTS.ENV.PRODUCT_NAME,
                  appsList,
                })
              }}</span>
            </div>
            <div class="section-apps__links">
              <slot-link
                v-for="(app, index) in apps"
                :key="index"
                :to="app.link.href"
                external
                class="section-apps__device-link"
              >
                <div class="section-apps__device-icon-wrapper">
                  <img
                    :src="
                      require(`@/assets/image/home/${app.name.toLowerCase()}.png`)
                    "
                    :alt="app.label"
                    :class="`section-apps__device-icon section-apps__device-icon--${app.name.toLowerCase()}`"
                  />
                </div>
                <span class="section-apps__device-label">
                  {{ app.label }}
                </span>
              </slot-link>
            </div>
            <link-button
              class="ection-apps__btn"
              :to="{ name: $ROUTER.NAME.PRICES }"
              :value="
                $t('web.getVPN', {
                  productName: $CONSTANTS.ENV.PRODUCT_NAME,
                })
              "
              color="default"
              transparent
              size="lg"
            />
          </div>
        </div>
      </div>
      <div
        class="section-apps__bg-image section-apps__bg-image--left"
        :style="`background-image: url(${require('@/assets/image/home/phoneandtab.jpg')});`"
      ></div>
      <div
        class="section-apps__bg-image section-apps__bg-image--right"
        :style="`background-image: url(${require('@/assets/image/home/macbook3.jpg')});`"
      ></div>
    </section>

    <section class="section section__servers section-servers">
      <div class="section-servers__wrapper section__wrapper">
        <div class="section-servers__content">
          <div class="section-servers__title">
            <h2 class="title title-lg color-main-opposite">
              {{ $t('web.enjoy_fast_and_stable_connection') }}
            </h2>
          </div>

          <div class="section-servers__description">
            <div class="section-servers__text">
              <span class="text text-lg color-main-opposite">{{
                $t('web.choose_your_server', {
                  count: $store.state.country.servers?.length || '',
                })
              }}</span>
            </div>
            <link-button
              class="ection-servers__btn"
              :to="{ name: $ROUTER.NAME.SERVERS }"
              :value="$t('web.our_vpn_server')"
              color="white"
              transparent
              size="lg"
            />
          </div>
        </div>
      </div>
      <div class="section-servers__bg-container">
        <div class="section-servers__bg"></div>
        <div
          class="section-servers__bg-image"
          :style="`background-image: url(${require('@/assets/image/home/servers-block.jpg')});`"
        ></div>
      </div>
    </section>

    <section class="section section__faq section-faq">
      <div class="section-faq__wrapper section__wrapper">
        <div class="section-faq__title">
          <h2 class="title title-lg text-center">
            {{ $t('web.home.faqTitle') }}
          </h2>
        </div>
        <div class="section-faq__list">
          <div
            v-for="(item, index) in faqItems"
            :key="index"
            class="section-faq__item"
          >
            <div
              class="section-faq__question"
              @click="openExpansionBlock(index)"
              :class="{
                opened: index === openingExpansionBlockIndex,
              }"
            >
              <span class="text text-lg">
                {{ item.title }}
              </span>
              <icon
                :name="$t('web.icon_down')"
                color="var(--main)"
                fill
                class="section-faq__title-icon"
                :class="{
                  'section-faq__title-icon--reverse':
                    index === openingExpansionBlockIndex,
                }"
                width="20"
                height="20"
              >
                <down />
              </icon>
            </div>

            <div
              class="section-faq__answer"
              :class="{
                opened: index === openingExpansionBlockIndex,
              }"
              :ref="setPaymentContentRef(index)"
            >
              <div
                class="section-faq__answer-wrapper"
                :ref="setPaymentContentWrapperRef(index)"
              >
                <span class="text text-md">
                  <p v-for="(paragraph, index) in item.paragraphs" :key="index">
                    <span
                      class="section-faq__paragraph"
                      v-for="(textAttribute, index) in paragraph.textAttributes"
                      :key="index"
                    >
                      <span v-if="textAttribute.tag === TAGS.SPAN">{{
                        textAttribute.content
                      }}</span>
                      <a
                        v-else-if="textAttribute.tag === TAGS.A"
                        :target="textAttribute.target"
                        :href="textAttribute.href"
                        >{{ textAttribute.content }}</a
                      >
                    </span>
                  </p>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="$CONSTANTS.ENV.APP_NAME === APP_NAME.VPN99"
          class="section-faq__footer"
        >
          <span>
            {{ $t('web.faq_footer_description') }}
            <a href="https://vpn99.zendesk.com/hc/en-001" target="_blank">
              {{ $t('web.faq_footer_knowledge-base') }}
            </a>
          </span>
        </div>
      </div>
    </section>

    <buy-now-section
      :title="
        $t(`web.download_VPN`, { productName: $store.state.app.productName })
      "
      :description="null"
      :buttonValue="$t(`getVPN`, { productName: $store.state.app.productName })"
      :btn-compressed="false"
    ></buy-now-section>
  </main>
</template>

<script>
import Icon from '@/components/Icon'
import checkCircle from '@/assets/image/icons/svg/checkCircle.svg'
import check from '@/assets/image/icons/svg/check.svg'
import down from '@/assets/image/icons/svg/down.svg'
import LinkButton from '@/components/LinkButton'
import SlotLink from '@/components/SlotLink'
import BuyNowSection from '@/components/Sections/BuyNow'
import { APPS } from '@/CONSTANTS'
import { APP_NAME } from '@/whitelabel/config'

export default {
  name: 'Home',
  components: {
    Icon,
    checkCircle,
    LinkButton,
    SlotLink,
    check,
    BuyNowSection,
    down,
  },
  data() {
    this.TAGS = {
      SPAN: 'span',
      A: 'a',
    }
    this.APP_NAME = APP_NAME
    return {
      openingExpansionBlockIndex: undefined,
      openedExpansionBlockIndex: undefined,
      paymentContentWrapperRefs: [],
      paymentContentRefs: [],
      timer: null,
      mainImgSrc: require(`@/whitelabel/${process.env.VUE_APP__APP_NAME}/image/home/main.png`),
    }
  },
  computed: {
    faqItems: {
      get() {
        return [
          {
            title: this.$t('web.home.faq.whatIsVPNTitle'),
            paragraphs: [
              {
                textAttributes: [
                  {
                    content: this.$t('web.home.faq.whatIsVPNDescription.p1'),
                    tag: this.TAGS.SPAN,
                  },
                ],
              },
              {
                textAttributes: [
                  {
                    content: this.$t('web.home.faq.whatIsVPNDescription.p2', {
                      productName: this.$store.state.app.productName,
                    }),
                    tag: this.TAGS.SPAN,
                  },
                ],
              },
            ],
          },
          {
            title: this.$t('web.home.faq.howWorksTitle'),
            paragraphs: [
              {
                textAttributes: [
                  {
                    content: this.$t('web.home.faq.howWorksDescription.p1'),
                    tag: this.TAGS.SPAN,
                  },
                ],
              },
            ],
          },
          {
            title: this.$t('web.home.faq.whatDiferenceWithProxyTitle'),
            paragraphs: [
              {
                textAttributes: [
                  {
                    content: this.$t(
                      'web.home.faq.whatDiferenceWithProxyDescription.p1'
                    ),
                    tag: this.TAGS.SPAN,
                  },
                ],
              },
            ],
          },
          {
            title: this.$t('web.home.faq.whatCountriesTitle'),
            paragraphs: [
              {
                textAttributes: [
                  {
                    content: `${this.$t('web.home.faq.whatCountries.first')} `,
                    tag: this.TAGS.SPAN,
                  },
                  {
                    content: this.$t('web.home.faq.whatCountries.link'),
                    href: this.$ROUTER.PATH[this.$ROUTER.NAME.SERVERS],
                    target: '_self',
                    tag: this.TAGS.A,
                  },
                  {
                    content: `. ${this.$t(
                      'web.home.faq.whatCountries.second'
                    )}`,
                    tag: this.TAGS.SPAN,
                  },
                ],
              },
            ],
          },
          {
            title: this.$t('web.home.faq.logsTitle'),
            paragraphs: [
              {
                textAttributes: [
                  {
                    content: this.$t('web.home.faq.logs.p1', {
                      productName: this.$store.state.app.productName,
                    }),
                    tag: this.TAGS.SPAN,
                  },
                ],
              },
            ],
          },
        ]
      },
    },
    apps: {
      get() {
        const list = this.$store.getters['apps/list'].map((object) => ({
          ...object,
        }))
        return list
          .sort((a, b) => (a.device > b.device ? -1 : 1))
          .reduce((acc, app) => {
            if (app.published) {
              const _app = { ...app }
              switch (app.name) {
                case APPS.DEVICE_NAME.IOS:
                  _app.name = 'apple'
              }
              acc.push(_app)
            }
            return acc
          }, [])
      },
    },
    appsList: {
      get() {
        return this.$store.getters['apps/list']
          .map((object) => ({ ...object }))
          .sort((a, b) => (a.device > b.device ? -1 : 1))
          .reduce((acc, app) => {
            if (app.published) {
              if (acc) {
                acc += ', '
              }
              acc += app.label
            }
            return acc
          }, '')
      },
    },
    pricePerMonth: {
      get() {
        const mainPaywallName =
          this.$store.state.whitelabel.config?.paywall?.default
        const mainTariffsConfig =
          require(`@/whitelabel/${process.env.VUE_APP__APP_NAME}/data/paywallConfigs/${mainPaywallName}.json`)
            .tariffs?.[
            process.env.VUE_APP__MODE === 'production' ? 'prod' : 'test'
          ]
        const allTariffs = this.$store.state.paywall.tariffs
        return mainTariffsConfig
          ? Math.min(
              ...allTariffs.reduce((acc, cur) => {
                if (
                  mainTariffsConfig.find(
                    (t) => t.stripeCode === cur.stripeTariffCode
                  )
                ) {
                  acc.push(cur.pricePerMonth)
                }
                return acc
              }, [])
            ).toFixed(2)
          : 'X.XX'
      },
    },
  },
  methods: {
    setPaymentContentRef(index) {
      return (el) => {
        if (el && !this.paymentContentRefs[index]) {
          this.paymentContentRefs[index] = el
        }
      }
    },
    setPaymentContentWrapperRef(index) {
      return (el) => {
        if (el && !this.paymentContentWrapperRefs[index]) {
          this.paymentContentWrapperRefs[index] = el
        }
      }
    },
    openExpansionBlock(index) {
      this.openingExpansionBlockIndex =
        this.openingExpansionBlockIndex === index &&
        typeof this.openingExpansionBlockIndex === 'number'
          ? null
          : index
      const expandedConetnt = this.paymentContentRefs[index]
      const expandedContentWrapper = this.paymentContentWrapperRefs[index]
      expandedConetnt.style.height = `${expandedContentWrapper.clientHeight}px`
      if (typeof this.openedExpansionBlockIndex === 'number') {
        const closedConetnt =
          this.paymentContentRefs[this.openedExpansionBlockIndex]
        const closedContentWrapper =
          this.paymentContentWrapperRefs[this.openedExpansionBlockIndex]
        closedConetnt.style.height = `${closedContentWrapper.clientHeight}px`
      }
      this.openedExpansionBlockIndex = this.openingExpansionBlockIndex
      setTimeout(() => {
        this.paymentContentRefs.forEach((_, i) => {
          if (i === index) {
            if (typeof this.openingExpansionBlockIndex !== 'number') {
              expandedConetnt.style.height = `0px`
            }
          } else {
            this.paymentContentRefs[i].style.height = `0px`
          }
        })
        if (this.timer) {
          clearTimeout(this.timer)
        }
        this.timer = setTimeout(() => {
          this.timer = null
          if (typeof this.openingExpansionBlockIndex === 'number') {
            expandedConetnt.style.height = 'auto'
          }
        }, 400) // TODO: время можно брать из стилей
      }, 0)
    },
  },
}
</script>

<style scoped lang="scss">
@import 'index';
</style>
